<template>
  <div class="traffic-container" :class="{'overflow': show}">
    <head-bar class="fixed-header" :title="$store.state.groupName || getNameByLocation()" @onClickLeft="onClickLeft" />
    <div id="container" :class="{'full_height': show}"></div>
    <div class="traffic-desc">
      <div class="fixed-btn" :icon="show ? 'arrow-up' : 'arrow-down'" type="primary" @click="handleDetailShow">
        <div class="icon-wrap">
          <van-icon :name="show ? 'arrow-up' : 'arrow-down'" color="#fff" />
        </div>
      </div>
      <van-sticky>
        <div class="traffic-desc-title">
          <span>车站</span>
          <span>|</span>
          <span>机场</span>
        </div>
      </van-sticky>
      <div class="traffic-desc-content">
        <div v-for="(item, index) in placeList" class="traffic-item" :key="index">
          <div class="item-main">
            <div class="item-right">
              <span @click="handleCarRoute(index)">打车路线</span>
              <span @click="handleBusRoute(index)">公交路线</span>
            </div>
            <div class="item-left">
              <span>{{ item.place }}</span>
            </div>
          </div>
          <div v-if="activeIndex === index" class="item-detail panel" id="panel"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headBar from '@/components/headBar/headBar'
import { mapState } from 'vuex'
export default {
  components: { headBar },
  data() {
    return {
      activeIndex: 0,
      activeType: '', // 1打车 2公交
      activeNames: [],
      show: false,
      place: {},
      placeList: []
    }
  },
  computed: {
    ...mapState({
      address: state => state.miceInfo.address,
      departAddress: state => state.miceInfo.departAddress
    })
  },
  mounted() {
    this.place = JSON.parse(this.address)
    this.placeList = JSON.parse(this.departAddress)
    // this.$nextTick(() => {
    //   this.baseMapDraw()
    // })
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getNameByLocation() {
      let name = ''
      if (this.$route.params.name) {
        name = this.$route.params.name
      }
      return name
    },
    handleDetailShow() {
      this.show = !this.show
    },
    handleCarRoute(i) {
      document.querySelector('#panel').innerHTML = ''
      if (this.activeIndex === i && this.activeType === 1) {
        this.activeType = ''
        return
      }
      this.activeType = 1
      this.activeIndex = i
      var map = new AMap.Map("container", {
        resizeEnable: true,
        // center: [this.place['lng'], this.place['lat']],
        zoom: 13 //地图显示的缩放级别
      })
      // 构造路线导航类
      var driving = new AMap.Driving({
        map: map,
        panel: "panel"
      })
      // 根据起终点经纬度规划驾车导航路线
      driving.search(new AMap.LngLat(this.placeList[i]['lng'], this.placeList[i]['lat']), new AMap.LngLat(this.place['lng'], this.place['lat']), function(status, result) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === 'complete') {
          // log.success('绘制驾车路线完成')
        } else {
          log.error('获取驾车数据失败：' + result)
        }
      })
    },
    handleBusRoute(i) {
      document.querySelector('#panel').innerHTML = ''
      if (this.activeIndex === i && this.activeType === 2) {
        this.activeType = ''
        return
      }
      this.activeType = 2
      this.activeIndex = i
      var map = new AMap.Map("container", {
        resizeEnable: true,
        // center: [this.place['lng'], this.place['lat']],
        zoom: 13 // 地图显示的缩放级别
      })
      var transOptions = {
        map: map,
        city: '北京',
        panel: 'panel',
        policy: AMap.TransferPolicy.LEAST_TIME
      }
      // 构造公交换乘类
      var transfer = new AMap.Transfer(transOptions);
      // 根据起、终点坐标查询公交换乘路线
      transfer.search(new AMap.LngLat(this.placeList[i]['lng'], this.placeList[i]['lat']), new AMap.LngLat(this.place['lng'], this.place['lat']), function (status, result) {
        // result即是对应的公交路线数据信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_TransferResult
        if (status === 'complete') {
          // log.success('绘制公交路线完成')
        } else {
          log.error('公交路线数据查询失败' + result)
        }
      })
    },
    baseMapDraw() {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [this.place['lng'], this.place['lat']],
        zoom: 13 // 地图显示的缩放级别
      })

      // 构造点标记
      var marker = new AMap.Marker({
        icon: "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
        position: [this.place['lng'], this.place['lat']]
      })
      map.add(marker)

      // 设置label标签
      // label默认蓝框白底左上角显示，样式className为：amap-marker-label
      // marker.setLabel({
      //   offset: new AMap.Pixel(-150, 100),  //设置文本标注偏移量
      //   content: `<div class='info label'>湖南省长沙市雨花区东山街道长沙南站(B12-B13检票口)</div>`, //设置文本标注内容
      //   direction: 'right' //设置文本标注方位
      // })

    }
  }
}
</script>
<style lang="less" scoped>
// html, body, #app {
//   height: 100%;
// }
.traffic-container {
  width: 100%;
  height: 100vh;
  #container {
    width: 100%;
    height: 296px;
    // margin-top: 46px;
  }
  .fixed-btn {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 200;
    width: 100px;
    height: 25px;
    background: #4CB5F9;
    border-radius: 20px 20px 0px 0px;
    text-align: center;
    .icon-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 24px;
      }
    }
  }

  .traffic-desc {
    position: relative;
    min-height: 100%;
    padding-top: 57px;
    background: linear-gradient(0deg, #F3F4F8, #F3F4F8, #FEFEFE);
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 0px 0px;
    // overflow-y: scroll;
    .traffic-desc-title {
      position: absolute!important;
      top: 0px;
      left: 0;
      width: 100%;
      font-size: 14px;
      color: #333333;
      font-weight: 400;
      position: relative;
      height: 57px;
      line-height: 57px;
      text-align: center;
      z-index: 99;
      span:nth-of-type(2) {
        margin: 0 20px;
      }
    }
  }

  .traffic-desc-content {
    height: 100%;
    padding: 0 15px;
    .traffic-item {
      position: relative;
      padding: 10px;
      margin-bottom: 15px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      .item-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        // border-bottom: 1px solid#C3CDD4;
      }
      .item-left {
        padding: 15px 0;
        flex: 1;
        color: #333333;
        font-size: 12px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      .item-left-active {
        border-bottom: 1px solid #C3CDD4;
      }
      .item-right {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 20px;
        span {
          margin-left: 10px;
          width: 60px;
          height: 20px;
          text-align: center;
          font-size: 10px;
          color: #666666;
          border: 1px solid #ccc;
          border-radius: 10px;
          background: #F7F8FA;
        }
      }
      .item-detail {
        margin-top: 10px;
        // overflow: hidden;
      }
    }
  }
}
/deep/ h4 {
  font-size: 14px !important;
}
.full_height {
  height: 100% !important;
}
.overflow {
  overflow: hidden;
}

.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

/deep/ .label {
  // text-align: center;
  // line-height: 30px;
  // color: white;
  // background: rgba(114, 192, 44, 0.85);
  // user-select: none;
  padding: 2px;
  margin: 0;
  min-width: none;
}

/deep/ .van-sticky--fixed {
  z-index: 999;
  .traffic-desc-title {
    background: #fff;
  }
}
</style>
